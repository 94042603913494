import { IKartData } from "../../context/SpeedControl";

const Row = ({ currentKart }: { currentKart: IKartData }) => {
  return (
    <tr data-kart-id={currentKart.kart}>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate">{currentKart.best_time_raw}</div>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate">{currentKart.kart}</div>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate">{(currentKart as any).total_laps}</div>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate">{currentKart.penalty}</div>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate">{currentKart.pos}</div>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate">{currentKart.top_speed}</div>
        </div>
      </td>

      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          <div className="truncate">{currentKart.speedset}</div>
        </div>
      </td>
      <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0 h-full">
          {/* <div className="truncate">{(currentKart as any).name}</div> */}
          <div className="truncate">{currentKart.display}</div>
        </div>
      </td>
    </tr>
  );
};

export default Row;
