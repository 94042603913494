import { useMemo } from "react";
import { useSpeedControl } from "../../context/SpeedControl";
import LoadingSpinner from "../../components/Shared/LoadingSpinner";
import Row from "./Row";

const Table = () => {
  const { isLoading, currentKarts } = useSpeedControl();
  const filteredCurrentKarts = useMemo(
    () => Object.values(currentKarts).sort((a, b) => a.pos - b.pos),
    [currentKarts]
  );
  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <div
        className={`dashboard-session-table-wrapper overflow-auto h-[calc(100%_-_3.5rem)] m-7`}
      >
        <table className="min-w-full h-[1px] ">
          <thead>
            <tr>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
                scope="col"
              >
                Best Time
              </th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
                scope="col"
              >
                Kart
              </th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
                scope="col"
              >
                Total Laps
              </th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
                scope="col"
              >
                Penalty
              </th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
                scope="col"
              >
                Pos
              </th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
                scope="col"
              >
                Top Speed
              </th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
                scope="col"
              >
                Speedset ID
              </th>
              <th
                className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10"
                scope="col"
              >
                Speedset Name
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300">
            {!filteredCurrentKarts.length && (
              <tr>
                <td colSpan={8} className="text-center p-2 text-gray-400">
                  No Records found
                </td>
              </tr>
            )}
            {filteredCurrentKarts.map((kart) => (
              <Row currentKart={kart} key={kart.kart} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
