import axios from "axios";
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { EDifficulty, TObjFromArray } from "../interfaces";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_SPEED_CONTROL_HOST}/api`,
  headers: {
    "x-api-token": process.env.REACT_APP_SPEED_CONTROL_API_TOKEN || "",
  },
});

export interface ISessionData {
  type: string;
  status_string: string;
  event_name: string;
  time_left: string;
  time_left_in_seconds: number;
  duration_type: string;
  total_laps: number;
  current_lap: number;
  has_pole: boolean;
  ranking_key: string;
  has_pits: boolean;
  has_stint: boolean;
  is_endurance: boolean;
  penalties: any[]; // You might want to define a more specific type for penalties
  runs: IKartData[];
  timestamp: number;
  timestamp_socket: number | null;
}

// interface IKartItem {
//   id: number;
//   pos: number;
//   pole: any; // You might want to define a more specific type for pole
//   country_code: string;
//   name: string;
//   team: string;
//   kart: string;
//   kart_id: number;
//   kart_color: string;
//   current_lap: string;
//   total_laps: number;
//   last_time: string;
//   last_time_short: string;
//   last_time_raw: number;
//   prev_time_raw: number | null;
//   best_time: string;
//   best_time_raw: number;
//   s1: string;
//   s2: string;
//   s3: string;
//   s4: string;
//   s1_status: string;
//   s2_status: string;
//   s3_status: string;
//   s4_status: string;
//   gap: string;
//   int: string;
//   number_of_pits: number;
//   total_driver_changes: string;
//   current_pit_time: string;
//   in_pit: boolean;
//   last_passing: number;
//   avg_lap: string;
//   avg_lap_raw: number;
//   consistency_lap: string;
//   consistency_lap_raw: number;
//   current_lap_start_timestamp: number;
//   current_lap_start_microtimestamp: number;
//   current_lap_milliseconds: number;
//   run_status: string;

//   // extra
//   Penalty: number;
//   TopSpeed: number;

//   //From rimo
//   speedsetID: number;
//   speedsetName: string;
// }

export interface IKartData {
  display: string;
  kart: number;
  loc: number;
  new_display: string;
  new_speedset: number;
  penalised: boolean;
  penalty: string;  // ISO date string
  serial: number;
  speedset: number;
  status: string;
  top_speed: number;
  
  name: string;
  best_time: string;
  best_time_raw: number | null;
  last_time: string;
  last_time_raw: number | null;
  heat_best_time: string | null;
  heat_best_time_raw: number | null;
  prev_time_raw: number | null;
  pos: number;
  time_left_clock: string;
  s1: string;
  s2: string;
  s3: string;
  s4: string;
}

interface ISpeedControlContextType {
  isLoading: boolean;
  currentKarts: TObjFromArray<IKartData>;
  isScriptRunning: boolean;
  difficulty: EDifficulty;
  isChangingScriptStatus: boolean;
  startScript: () => Promise<any>;
  stopScript: () => Promise<any>;
  changeDifficulty: (difficulty: EDifficulty) => Promise<any>;
}

const SpeedControlContext = createContext<ISpeedControlContextType>(
  undefined as unknown as ISpeedControlContextType
);

const SpeedControlContextProvider: FC<
  PropsWithChildren<{ value: ISpeedControlContextType }>
> = ({ value, children }) => {
  return (
    <SpeedControlContext.Provider value={value}>
      {children}
    </SpeedControlContext.Provider>
  );
};

export const useSpeedControl = () => {
  return useContext<ISpeedControlContextType>(SpeedControlContext);
};

interface Type {}
const SpeedControlProvider: FC<PropsWithChildren<Type>> = ({ children }) => {
  const [isChangingScriptStatus, setIsChangingScriptStatus] = useState(false);
  const [difficulty, setDifficulty] = useState<EDifficulty>(EDifficulty.Normal);
  const [isScriptRunning, setIsScriptRunning] = useState(false);
  const [currentKarts, setCurrentKarts] = useState<TObjFromArray<IKartData>>(
    {}
  );
  const [isLoading, setIsLoading] = useState(false);

  const fetchCurrentKartData = useCallback((withLoader = false) => {
    withLoader && setIsLoading(true);
    api
      .get("/speed-control/data")
      .then(({ data }) => {
        if (data.success && Array.isArray(data.data)) {
          const karts: IKartData[] = data.data;
          console.log("karts", karts);

          setCurrentKarts(
            karts.reduce(
              (acc, kartData) => ({ ...acc, [kartData.kart]: kartData }),
              {}
            )
          );
        }
      })
      .catch(console.error)
      .finally(() => {
        withLoader && setIsLoading(false);
      });
  }, []);

  const checkScriptStatus = useCallback(() => {
    api
      .get("/speed-control/status")
      .then((scriptStatusResponse: any) => {
        if (scriptStatusResponse.data.success) {
          console.log("scriptStatusResponse.data", scriptStatusResponse.data)
          setIsScriptRunning(scriptStatusResponse.data.data.isRunning);
          setDifficulty(
            scriptStatusResponse.data.data.difficulty || EDifficulty.Normal
          );
        }
      })
      .catch(console.error);
  }, []);
  const startScript = useCallback(async () => {
    setIsChangingScriptStatus(true);
    try {
      const startScriptResponse = await api.post("/speed-control/script/start", {
        difficulty: EDifficulty.Normal,
      });

      if (startScriptResponse.data.success) {
        setIsScriptRunning(true);
      }
    } catch (error) {
      console.log("error", error);
      alert(error);
    } finally {
      setIsChangingScriptStatus(false);
    }
  }, []);
  const stopScript = useCallback(async () => {
    setIsChangingScriptStatus(true);
    try {
      const stopScriptResponse = await api.post("/speed-control/script/stop");
      if (stopScriptResponse.data.success) {
        setIsScriptRunning(false);
      }
    } catch (error) {
      console.log("error", error);
      alert(error);
    } finally {
      setIsChangingScriptStatus(false);
    }
  }, []);

  const changeDifficulty = useCallback(async (difficulty: EDifficulty) => {
    try {
      const changeDifficultyResponse = await api.post("/speed-control/difficulty", {
        difficulty,
      });

      if (changeDifficultyResponse.data.success) {
        console.log("changeDifficultyResponse", changeDifficultyResponse);
        // setDifficulty(changeDifficultyResponse.data.data.difficulty);
      }
    } catch (error) {
      console.log("error", error);
      alert(error);
    }
  }, []);

  useEffect(() => {
    fetchCurrentKartData(true);
    checkScriptStatus();
  }, [fetchCurrentKartData, checkScriptStatus]);

  useEffect(() => {
    const intervals: NodeJS.Timer[] = [];
    intervals.push(setInterval(fetchCurrentKartData, 10 * 1000));
    intervals.push(setInterval(checkScriptStatus, 1000));
    return () => {
      intervals.forEach((interval) => {
        if (interval) {
          clearInterval(interval);
        }
      });
    };
  }, [fetchCurrentKartData, checkScriptStatus]);

  return (
    <SpeedControlContextProvider
      value={{
        isLoading,
        currentKarts,
        isScriptRunning,
        difficulty,
        isChangingScriptStatus,
        startScript,
        stopScript,
        changeDifficulty,
      }}
    >
      {children}
    </SpeedControlContextProvider>
  );
};

export default SpeedControlProvider;
