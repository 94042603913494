import React, { useCallback, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Calender from "./Calender";
import ComboButton from "../../components/Shared/ComboButton";
import SearchBar from "../../components/Shared/SearchBar";
import { RefreshIcon } from "@heroicons/react/solid";
import { useModal } from "../../context/Modal";
import { useEventContext } from "./useEventsContext";
import { useFirebase } from "../../components/Firebase";
import {
  CollectionReference,
  collection,
  setDoc,
  doc,
} from "firebase/firestore";
import DropDown from "../../components/Shared/DropDown";
import { EEventsSortOptions, TDashboardView } from "./useEventDay";
import { TEvent } from "../../interfaces";

interface Props {
  changeDashboardView: (val: TDashboardView) => void;
  sort: EEventsSortOptions;
  applySort: (val: EEventsSortOptions) => void;
}

const ModalContent = () => {
  const [isCreatingEvent, setIsCreatingEvent] = useState(false)
  const { setshowModal } = useModal();
  const { dispatch, createEvent, createEventsList } = useEventContext();
  const isEveryThingFilled: boolean = useMemo(
    () => (createEvent.eventClosure && createEvent.eventName ? true : false),
    [createEvent.eventClosure, createEvent.eventName]
  );
  const firebase = useFirebase();
  const db = useMemo(() => firebase.db, [firebase]);
  const eventDayRef = useMemo(
    () => collection(db, "event_day") as CollectionReference<TEvent>,
    [db]
  );

  const handleCreateEvent = async () => {
    setIsCreatingEvent(true);
    try {
      const id = uuidv4();
      const eventData: TEvent = {
        ...createEvent,
        id,
        enableEditing: false,
        isClosed: false,
      };
      await setDoc(doc(eventDayRef, id), eventData);
      dispatch({
        type: "SET_CREATED_EVENTS_LISTS_STATE",
        payload: { createEventsList: [...createEventsList, eventData] },
      });
      setshowModal(false);
    } catch (error) {
      alert(error)
      console.log(error);
    } finally{
    setIsCreatingEvent(false);
    }
  };

  return (
    <div className="w-full mt-[50px]">
      <div className="flex items-center justify-center gap-[10px]">
        <div className="w-full">
          <label
            className="block text-[#2F1160] text-sm font-[700] mb-2"
            htmlFor="event_name"
          >
            Event Name
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="event_name"
            type="text"
            placeholder="Enter event name"
            onChange={(e) =>
              dispatch({
                type: "SET_CREATE_EVENT_STATE",
                payload: {
                  createEvent: { ...createEvent, eventName: e.target.value },
                },
              })
            }
            value={createEvent.eventName || ""}
          />
        </div>
        <div className="w-full">
          <label
            className="block text-[#2F1160] text-sm font-[700] mb-2"
            htmlFor="event_date"
          >
            Event Date
          </label>
          <Calender layout="Create Event" />
        </div>
      </div>
      <div className="w-full mt-[20px]">
        <label
          className="block text-[#2F1160] text-sm font-[700] mb-2"
          htmlFor="relevant_closures"
        >
          Relevant Closures
        </label>
        <textarea
          id="relevant_closures"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          onChange={(e) =>
            dispatch({
              type: "SET_CREATE_EVENT_STATE",
              payload: {
                createEvent: { ...createEvent, eventClosure: e.target.value },
              },
            })
          }
          value={createEvent.eventClosure || ""}
        />
      </div>
      <div className="flex items-center justify-center gap-[10px] mt-[20px]">
        <div className="w-full">
          <span className="block text-[#2F1160] text-sm font-[700] mb-2">
            SMS
          </span>
          <div
            className="cursor-pointer block"
            onClick={() =>
              dispatch({
                type: "SET_CREATE_EVENT_STATE",
                payload: {
                  createEvent: { ...createEvent, isSMS: !createEvent.isSMS },
                },
              })
            }
          >
            {createEvent.isSMS ? (
              <svg
                width="48"
                height="25"
                viewBox="0 0 48 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="48" height="25" rx="12.5" fill="#2EBF3C" />
                <circle cx="35.5" cy="12.5" r="10.5" fill="white" />
              </svg>
            ) : (
              <svg
                width="48"
                height="25"
                viewBox="0 0 48 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="48" height="25" rx="12.5" fill="#D9D9D9" />
                <circle cx="12.5" cy="12.5" r="10.5" fill="white" />
              </svg>
            )}
          </div>
        </div>
        <div className="w-full">
          <span className="block text-[#2F1160] text-sm font-[700] mb-2">
            Email
          </span>
          <div
            className="cursor-pointer block"
            onClick={() =>
              dispatch({
                type: "SET_CREATE_EVENT_STATE",
                payload: {
                  createEvent: { ...createEvent, isEmail: !createEvent.isEmail },
                },
              })
            }
          >
            {createEvent.isEmail ? (
              <svg
                width="48"
                height="25"
                viewBox="0 0 48 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="48" height="25" rx="12.5" fill="#2EBF3C" />
                <circle cx="35.5" cy="12.5" r="10.5" fill="white" />
              </svg>
            ) : (
              <svg
                width="48"
                height="25"
                viewBox="0 0 48 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="48" height="25" rx="12.5" fill="#D9D9D9" />
                <circle cx="12.5" cy="12.5" r="10.5" fill="white" />
              </svg>
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-end items-center mt-[20px] gap-[10px]">
        <button
          type="button"
          className="text-[14px] font-[700] py-[13px] px-[30px] text-[#9D62FE] border-[1px] border-[#9D62FE] rounded-full"
          onClick={() => setshowModal(false)}
        >
          Cancel
        </button>
        <button
          type="button"
          className={`text-[14px] font-[700] py-[13px] px-[30px] text-white bg-[#9D62FE] rounded-full ${
            !isEveryThingFilled
              ? "cursor-not-allowed opacity-[0.5]"
              : "cursor-pointer"
          }`}
          disabled={!isEveryThingFilled}
          onClick={handleCreateEvent}
        >
          {isCreatingEvent ?"...":"Create"}
        </button>
      </div>
    </div>
  );
};

function Header({ changeDashboardView, sort, applySort }: Props) {
  const { setModalData, setshowModal } = useModal();
  const { refetchEvents } = useEventContext();

  const handleRefresh = useCallback(() => {
    // restore the data back
    refetchEvents();
  }, [refetchEvents]);

  const handleCreateEventButtonClick = useCallback(() => {
    setModalData({
      id: uuidv4(),
      title: "Create New Event" as string,
      onClose: () => setshowModal(false),
      content: <ModalContent />,
      className: "createEvent",
    });
    setshowModal(true);
  }, [setModalData, setshowModal]);

  return (
    <div className="flex min-w-3 w-[100%] justify-between items-center gap-4 h-12">
      <div className="w-1/3 h-full">
        <SearchBar onChange={console.log} />
      </div>
      <DropDown
        items={[
          {
            key: "ASCENDING",
            label: "Ascending",
          },
          {
            key: "DESCENDING",
            label: "Descending",
          },
        ]}
        selected={sort}
        onSelect={(val) => applySort(val as EEventsSortOptions)}
      />
      <ComboButton
        leftButtonName="Open"
        rightButtonName="Closed"
        onChange={(val) => {
          if (val === "LEFT") {
            changeDashboardView("Open");
          } else if (val === "RIGHT") {
            changeDashboardView("Closed");
          }
        }}
      />
      <div className="flex items-center gap-[10px]">
        <button
          onClick={handleCreateEventButtonClick}
          type="button"
          className="bg-[#9D62FE] text-white font-[700] text-[14px] py-[15px] px-[20px] rounded-[10px]"
        >
          Create Event
        </button>
        <div
          className="cursor-pointer h-full bg-gray-800 flex items-center rounded px-3"
          onClick={handleRefresh}
        >
          <RefreshIcon className="text-white w-6 h-[48px]" />
        </div>
      </div>
    </div>
  );
}

export default Header;
