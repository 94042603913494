import { useCallback, useEffect, useState } from "react";
import {
  TBookingType,
  TDashboardView,
  TMainTrackAgeFilters,
} from "../../interfaces";
import axios from "axios";
import { DateTime } from "luxon";

function useDashboard() {
  const [view, setView] = useState<TBookingType>("MAIN_TRACK");
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [dashboardView, setDashboardView] = useState<TDashboardView>("SIMPLE_VIEW");
  const [ageFilter, setAgeFilter] = useState<TMainTrackAgeFilters>(TMainTrackAgeFilters.ALL);
  const [wetherData, setWetherData] = useState({})

  const changeView = useCallback((val: TBookingType) => {
    setView(val);
  }, []);

  const changeSelectedDate = useCallback((val: Date) => {
    setSelectedDate(val);
  }, []);

  const changeDashboardView = useCallback((val: TDashboardView) => {
    setDashboardView(val);
  }, []);

  const changeAgeFilter = useCallback((val: TMainTrackAgeFilters) => {
    setAgeFilter(val);
  }, []);

  const refreshWetherData = useCallback(async () => {
    try {
      // const { data } = await axios.get(`http://127.0.0.1:5001/hyper-karting/us-central1/weather`, {
      const { data } = await axios.get(`https://us-central1-hyper-karting.cloudfunctions.net/weather`, {
        params: {
          ts: selectedDate.getTime()
        }
      });
      if(data.success){
        setWetherData(data.data || {})
      }
    } catch (error) {
      console.log("Error in fetching wether data", error)
    }
  },[selectedDate])

  useEffect(() => {
    refreshWetherData()
  }, [refreshWetherData])

  useEffect(() => {
    const updateSelectedDate = () => {
      if(window){
        const ts = window.localStorage.getItem(selectedDateTsKey);
        if(ts){
          const dt1 = DateTime.fromMillis(parseInt(ts)).startOf('day');
          const dt2 = DateTime.fromJSDate(selectedDate).startOf('day');
          if(dt1.toMillis() !== dt2.toMillis()){
            setSelectedDate(dt1.toJSDate())
          }
        }
      }
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        updateSelectedDate()
      }
    };


    if(window){
      window.localStorage.setItem(selectedDateTsKey, DateTime.fromJSDate(selectedDate).startOf('day').toMillis().toString())
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [selectedDate])
  

  return {
    view,
    selectedDate,
    dashboardView,
    ageFilter,
    wetherData,
    changeView,
    changeSelectedDate,
    changeDashboardView,
    changeAgeFilter,
  };
}

const selectedDateTsKey = "selected-date-ts";

export default useDashboard;
