import { useMemo } from "react";
import { TBookingType } from "../../interfaces";
import { ILineItemWithOrder } from "../../interfaces/Session";
import {
  ARCADE_CARD_PRODUCT_ID,
  BOOKING_INSURANCE_PRODUCT_ID,
  INACTIVE_SUBSCRIBER,
  PIZZA_PRODUCT_ID,
  TAG_FRAUDULENCE_CHECKED,
  TAG_KPR_ORDER,
  TRIAL_SUBSCRIBER,
  VIP_MEMBER,
} from "../../constants/Dashboard";
import { Tooltip } from "@material-tailwind/react";
import { ChatAlt2Icon, ClipboardCheckIcon, CreditCardIcon, DesktopComputerIcon, StarIcon, UserIcon, PencilAltIcon, CakeIcon, CubeIcon, FireIcon } from "@heroicons/react/solid";
import ArcadeCardTooltip from "./ArcadeCardTooltip";
import { IFirebaseOrder } from "../../interfaces/firebaseEntities";
import { getTrackFromSku } from "../../utilities";
import { isBookedByMember } from "./utilities";
import OrderRiskIcon from "./OrderRiskIcon";

function Notes({ booking, view, refreshSessions }: { booking: ILineItemWithOrder; view: TBookingType; refreshSessions: () => void }) {
  const orderId = useMemo(() => booking?.order?.id, [booking]);
  const orderBadgeData = useMemo(() => {
    const { line_items } = booking.order;
    const races = line_items.filter((li) => getTrackFromSku(li.sku) === view && (li as any).current_quantity && li.product_id !== BOOKING_INSURANCE_PRODUCT_ID); // filter based on track and remove refunded items
    const times = races
      .sort((a, b) => {
        return (a.variant_id as number) - (b.variant_id as number);
      })
      .map((x) => {
        if (view === "VR") {
          const nameSplit = x.variant_title.split("/");
          return nameSplit[0]?.trim();
        }
        const nameSplit = x.name.split(" ");
        return nameSplit[nameSplit.length - 1]?.trim();
      });

    return {
      races,
      times,
    };
  }, [booking, view]);

  const isVipBooking = useMemo(
    () =>
      !!booking.order.customer?.tags
        ?.split(",")
        ?.map((e) => e.trim())
        ?.find((e) => VIP_MEMBER.includes(e)),
    [booking]
  );

  const insuredBooking = useMemo(() => !!booking.order.line_items.find((li) => li.product_id === BOOKING_INSURANCE_PRODUCT_ID), [booking]);

  const bookedByActiveSubscriber = useMemo(() => isBookedByMember(booking), [booking]);

  const customerOnTrialMembership = useMemo(() => booking.order.customer?.tags?.includes(TRIAL_SUBSCRIBER), [booking]);

  const customerHasInactiveMembership = useMemo(() => booking.order.customer?.tags?.includes(INACTIVE_SUBSCRIBER), [booking]);

  const isKidsPartyRoomOrder = useMemo(() => view === "MINI_TRACK" && booking.order.tags.includes(TAG_KPR_ORDER), [booking, view]);

  const purchasedArcadeCard = useMemo(() => booking.order.line_items.find((li) => li.product_id === ARCADE_CARD_PRODUCT_ID), [booking]);

  const rowId = useMemo(() => `id-${booking.variant_id}-${booking.order.id}`, [booking]);

  const purchasedCatering = useMemo(() => booking.order.line_items.find((li) => li.product_id === 8577859223712), [booking]);

  const orderNote = useMemo(() => booking.order.note, [booking]);

  const risks = useMemo(() => ((booking.order as IFirebaseOrder)?.risks || []).filter((risk) => risk.recommendation !== "accept") || [], [booking]);

  const purchasedPizza = useMemo(() => booking.order.line_items.find((li) => li.product_id === PIZZA_PRODUCT_ID), [booking]);

  const fraudulenceChecked = useMemo(() => booking.order.tags.includes(TAG_FRAUDULENCE_CHECKED), [booking]);

  return (
    <div key={booking.id} className={`border-b flex flex-row items-center`}>
      {risks.map((risk) => (
        <OrderRiskIcon key={risk.id} risk={risk} orderId={orderId} fraudulenceChecked={fraudulenceChecked} onClick={refreshSessions} />
      ))}

      <Tooltip content={`${orderBadgeData.times?.join(" ")}`}>
        <span style={{ background: "#d32efe" }} className="text-white flex flex-col justify-center text-center font-bold w-6 h-5 rounded-full ">
          {orderBadgeData.races.length}
        </span>
      </Tooltip>
      {booking.order.source_name === "web" && (
        <Tooltip content="Ordered on web">
          <DesktopComputerIcon fill="#4f0082" width={"1.75rem"} height={"1.75rem"} />
        </Tooltip>
      )}
      {booking.order.source_name === "pos" && (
        <Tooltip content="Ordered on POS">
          <CreditCardIcon fill="#c69ece" width={"1.75rem"} height={"1.75rem"} />
        </Tooltip>
      )}
      {booking.order.source_name !== "pos" && booking.order.source_name !== "web" && (
        <Tooltip content="Draft Order">
          <PencilAltIcon fill="#58008a" width={"1.75rem"} height={"1.75rem"} />
        </Tooltip>
      )}
      {booking.order?.note && (
        <Tooltip content={orderNote}>
          <ChatAlt2Icon fill="#8a75ff" width={"1.75rem"} height={"1.75rem"} />
        </Tooltip>
      )}
      {insuredBooking && (
        <Tooltip content="Booking Protection">
          <ClipboardCheckIcon fill="#8a75ff" width={"30"} />
        </Tooltip>
      )}

      {isVipBooking && (
        <Tooltip content="VIP">
          <StarIcon fill="#ff96ff" width={"1.75rem"} height={"1.75rem"} />
        </Tooltip>
      )}
      {bookedByActiveSubscriber && (
        <Tooltip content={customerOnTrialMembership ? "Trial Member" : "Member"}>
          <UserIcon fill={customerOnTrialMembership ? "red" : "#9100be"} width={"1.75rem"} height={"1.75rem"} />
        </Tooltip>
      )}
      {customerHasInactiveMembership && (
        <Tooltip content="Member">
          <UserIcon fill="#9100be" width={"1.75rem"} height={"1.75rem"} />
        </Tooltip>
      )}
      {isKidsPartyRoomOrder && (
        <Tooltip content="Kids Party Room">
          <CakeIcon fill="#8a75ff" width={"2rem"} />
        </Tooltip>
      )}
      {purchasedCatering && (
        <Tooltip content="Catering">
          <FireIcon fill="#00a89d" width={"1.7rem"} />
        </Tooltip>
      )}
      {purchasedArcadeCard && (
        <ArcadeCardTooltip order={booking.order as IFirebaseOrder} rowId={rowId}>
          <CubeIcon fill="#8a75ff" width={"1.75rem"} height={"1.75rem"} className={`cursor-pointer ${rowId}`} />
        </ArcadeCardTooltip>
      )}
      {purchasedPizza && (
        <Tooltip content="Pizza Catering. Please pick up order">
          <div className="w-[1.5rem] cursor-pointer">
            <PizzaSvgIcon className="text-red-500" />
          </div>
        </Tooltip>
      )}
    </div>
  );
}

const PizzaSvgIcon = ({ className }: { className: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="M169.7.9c-22.8-1.6-41.9 14-47.5 34.7L110.4 80h1.6c176.7 0 320 143.3 320 320v1.6l44.4-11.8c20.8-5.5 36.3-24.7 34.7-47.5C498.5 159.5 352.5 13.5 169.7.9zm230.1 409.3c.1-3.4.2-6.8.2-10.2c0-159.1-128.9-288-288-288c-3.4 0-6.8.1-10.2.2L.5 491.9c-1.5 5.5.1 11.4 4.1 15.4s9.9 5.6 15.4 4.1l379.8-101.2zM176 208a32 32 0 1 1 0 64a32 32 0 1 1 0-64zm64 128a32 32 0 1 1 64 0a32 32 0 1 1-64 0zM96 384a32 32 0 1 1 64 0a32 32 0 1 1-64 0z"
    />
  </svg>
);

export default Notes;
