import React, { useMemo } from 'react'
import { ILineItemWithOrder } from '../../interfaces/Session'
import { isBookedByMember } from './utilities'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { Tooltip } from '@material-tailwind/react'

const Name = ({ booking, hasMultipleBooking, refundedOrReturned }: { booking: ILineItemWithOrder, hasMultipleBooking: boolean, refundedOrReturned: boolean }) => {
    const isMember = useMemo(() => isBookedByMember(booking), [booking])
    return (
        <div key={booking.id} className={`truncate py-2 border-b flex flex-row items-center`}>

            {(isMember && hasMultipleBooking) ? (
                <Tooltip content={"Multiple member bookings in a single session"}>
                    <p className={`${hasMultipleBooking ? "text-red-500": ""}`}>
                        {booking.order.customer?.first_name}&nbsp;
                        {booking.order.customer?.last_name}&nbsp;
                    </p>
                </Tooltip>
            ):(
                <p className={`${refundedOrReturned ? "line-through": ""}`}>
                    {booking.order.customer?.first_name}&nbsp;
                    {booking.order.customer?.last_name}&nbsp;
                </p>
            )}

            {(isMember && hasMultipleBooking) && <ExclamationCircleIcon fill="#F53B3B" width={"1rem"} className="mr-1" />}
        </div>
    )
}

export default Name