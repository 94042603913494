import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Fragment, forwardRef, useCallback, useMemo, useState } from "react";
import useNavigation, {
  IPrimaryNavItem,
  ISecondaryNavItem,
} from "../../hooks/useNavigation";
import { classNames } from "../Utilities";
import LogoutButton from "./LogoutButton";
import HyperKartingLogo from "../../images/hk_logo.svg";

const PrimaryNavItem = ({ item }: { item: IPrimaryNavItem }) => {
  return (
    <a
      href={item?.href}
      className={classNames(
        item.current
          ? "bg-gray-100 text-gray-900"
          : "text-gray-100 hover:text-gray-900 hover:bg-gray-50",
        "group flex items-center px-2 py-2 text-sm leading-5 font-medium rounded-md"
      )}
      aria-current={item.current ? "page" : undefined}
    >
      <item.icon
        className={classNames(
          item.current
            ? "text-gray-500"
            : "text-gray-400 group-hover:text-gray-500",
          "mr-3 flex-shrink-0 h-6 w-6"
        )}
        aria-hidden="true"
      />
      {item.name}
    </a>
  );
};

const SecondaryNavItem = ({ item }: { item: ISecondaryNavItem }) => {
  const [showChilds, setShowChilds] = useState(false);
  const childOpen = useMemo(
    () => item.childrens.find((children) => children.current),
    [item]
  );
  const toggleChilds = useCallback(() => {
    setShowChilds((oldVal) => !oldVal);
  }, []);

  return (
    <div className="bg-white bg-opacity-5 flex flex-col gap-2">
      <div
        className={classNames(
          childOpen
            ? "bg-gray-50 text-gray-900 bg-opacity-50"
            : "text-gray-100 hover:text-gray-900 hover:bg-gray-50 hover:bg-opacity-50",
          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
        )}
        onClick={toggleChilds}
      >
        {item.name}
        <svg
          className={`ml-auto w-4 h-4 transform transition-transform ${
            showChilds || childOpen ? "rotate-90" : ""
          }`}
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m8.25 4.5 7.5 7.5-7.5 7.5"
          />
        </svg>
      </div>
      {(showChilds || childOpen) &&
        item.childrens.map((childItem) => (
          <PrimaryNavItem item={childItem} key={childItem.name} />
        ))}
    </div>
  );
};

const SideBarDialog = (props: SideBarDialogProps, ref: any) => {
  const { setSidebarOpen } = props;
  const navigation = useNavigation();

  return (
    <Dialog
      as="div"
      className="relative z-40 xl:hidden"
      onClose={setSidebarOpen}
      ref={ref}
    >
      <Transition.Child
        as={Fragment}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </Transition.Child>

      <div className="fixed inset-0 flex z-40">
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <Dialog.Panel className="relative flex flex-col pt-5 pb-4 bg-gray-900 w-64">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 flex items-center px-6">
              <img
                className="h-20 w-auto"
                src={HyperKartingLogo}
                alt="Hyper Karting"
              />
            </div>
            <div className="mt-5 flex-1 h-0 overflow-y-auto">
              <nav className="px-3">
                <div className="space-y-1">
                  {navigation.map((item) =>
                    item.type === "primary" ? (
                      <PrimaryNavItem item={item} key={item.name} />
                    ) : (
                      <SecondaryNavItem key={item.name} item={item} />
                    )
                  )}
                </div>
                <hr className="mt-1" />
                <LogoutButton setSidebarOpen={setSidebarOpen} />
              </nav>
            </div>
          </Dialog.Panel>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true">
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </div>
    </Dialog>
  );
};

interface SideBarDialogProps {
  setSidebarOpen: (value: boolean) => void;
}

export default forwardRef(SideBarDialog);
