import BookingSessionProvider from "../../context/BookingSession";
import CheckInProvider from "../../context/Checkin";
import StaffInfoProvider from "../../context/Staff";
import { TBookingType } from "../../interfaces";
import { PageLayout } from "../../layouts";
import Header from "./Header";
import SessionTable from "./SessionTable";
import useDashboard from "./useDashboard";
import ModalProvider from "../../context/Modal";

export const Dashboard = () => {
  const { view, selectedDate, dashboardView, ageFilter, wetherData, changeDashboardView, changeSelectedDate, changeView, changeAgeFilter } = useDashboard();

  return (
    <>
      <BookingSessionProvider date={selectedDate} track={view} wetherData={wetherData}>
        <StaffInfoProvider date={selectedDate}>
          <CheckInProvider date={selectedDate}>
            <ModalProvider>
              <PageLayout
                Header={
                  <Header
                    view={view}
                    selectedDate={selectedDate}
                    changeView={(val) => {
                      changeView(val as TBookingType);
                    }}
                    changeSelectedDate={changeSelectedDate}
                    changeDashboardView={changeDashboardView}
                  />
                }
              >
                <SessionTable dashboardView={dashboardView} ageFilter={ageFilter} changeAgeFilter={changeAgeFilter} />
              </PageLayout>
            </ModalProvider>
          </CheckInProvider>
        </StaffInfoProvider>
      </BookingSessionProvider>
    </>
  );
};
