import React, { Fragment, useMemo, useState } from "react";
import Calender from "../../components/Shared/Calender";
import ComboButton from "../../components/Shared/ComboButton";
import DropDown from "../../components/Shared/DropDown";
import SearchBar from "../../components/Shared/SearchBar";
import { TBookingType, TDashboardView } from "../../interfaces";
import { RefreshIcon } from "@heroicons/react/solid";
import { useBookingSession } from "../../context/BookingSession";
import { useSession } from "../../components/Session";
import HamBurger from "../../components/Shared/HamBurger";
import { Transition } from "@headlessui/react";
import SideBarDialog from "../../components/Shared/SideBarDialog";
import { getTrackDisplayName } from "../../utilities";

interface Props {
  view: TBookingType;
  selectedDate: Date;
  changeView: (val: string) => void;
  changeSelectedDate: (val: Date) => void;
  changeDashboardView: (val: TDashboardView) => void;
}
function Header({ view, selectedDate, changeView, changeSelectedDate, changeDashboardView }: Props) {
  const { product, refreshSessions, applySearchFilter } = useBookingSession();
  const session = useSession();
  const trackStaff = useMemo(() => session.permissions?.roles?.includes("TRACK_STAFF"), [session]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const productHandle = useMemo(()=>product?.handle, [product]);

  const isDashboardRoute = useMemo(() => {
    if (typeof window !== "undefined") {
      return window.location.pathname === "/dashboard";
    }
    return false;
  }, []);

  return (
    <div className="flex min-w-3 w-[100%] justify-between items-center gap-4 h-12">
      {isDashboardRoute && (
        <>
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <SideBarDialog setSidebarOpen={setSidebarOpen} />
          </Transition.Root>

          <HamBurger setSidebarOpen={setSidebarOpen} />
        </>
      )}

      <div className="w-1/3 h-full">
        <SearchBar onChange={applySearchFilter} />
      </div>

      <Calender onDateChange={changeSelectedDate} value={selectedDate} copyText={productHandle} copyTooltip="Copy Product SKU" persistValue/>

      <ComboButton
        leftButtonName="Simple"
        rightButtonName="Complex"
        hideRightButton={trackStaff}
        onChange={(val) => {
          if (val === "LEFT") {
            changeDashboardView("SIMPLE_VIEW");
          } else if (val === "RIGHT") {
            changeDashboardView("COMPLEX_VIEW");
          }
        }}
      />

      <DropDown
        items={[
          {
            key: "MAIN_TRACK",
            label: getTrackDisplayName("MAIN_TRACK"),
          },
          {
            key: "MINI_TRACK",
            label: getTrackDisplayName("MINI_TRACK"),
          },
          {
            key: "VR",
            label: getTrackDisplayName("VR"),
          },
          {
            key: "JUNIOR_TRACK",
            label: getTrackDisplayName("JUNIOR_TRACK"),
          },
          {
            key: "INTERMEDIATE_TRACK",
            label: getTrackDisplayName("INTERMEDIATE_TRACK"),
          },
        ]}
        selected={view}
        onSelect={changeView}
      />

      <div className="cursor-pointer h-full bg-gray-800 flex items-center rounded px-3" onClick={refreshSessions}>
        <RefreshIcon className="text-white w-6" />
      </div>
    </div>
  );
}

export default Header;
