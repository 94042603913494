import React, { useCallback, useEffect, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Calendar } from "react-date-range";
import { DateTime } from "luxon";
import { ChevronLeftIcon, ChevronRightIcon, CalendarIcon, ClipboardCopyIcon } from "@heroicons/react/solid";
import OutsideClickHandler from "react-outside-click-handler";
import { Tooltip } from "@material-tailwind/react";
import { copyToClipboard } from "../../utilities";

interface Props {
  defaultValue?: Date;
  value?: Date;
  copyText?: string;
  copyTooltip?: string;
  persistValue?: boolean;
  onDateChange?: (date: Date) => void;
}

const Calender = ({ onDateChange, defaultValue, value, copyText, copyTooltip, persistValue }: Props) => {
  const [selectedDate, setSelectedDate] = useState<Date>(defaultValue || new Date());
  const [showDateRange, setShowDateRange] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState(false);

  const changeSelectedDate = useCallback((date: Date) => {
    setSelectedDate(date);
    setShowDateRange(false);
    onDateChange && onDateChange(date);
  }, [onDateChange]);

  const handleSelect = useCallback((date: Date) => {
    changeSelectedDate(date)
  }, [changeSelectedDate]);

  const handlePrevDayClick = useCallback(() => {
    const date = DateTime.fromJSDate(selectedDate).minus({ days: 1 });
    changeSelectedDate(date.toJSDate())
  }, [selectedDate, changeSelectedDate]);

  const handleNextDayClick = useCallback(() => {
    const date = DateTime.fromJSDate(selectedDate).plus({ days: 1 });
    changeSelectedDate(date.toJSDate())
  }, [selectedDate, changeSelectedDate]);

  const onCopyButtonClick = useCallback(() => {
    if(copyText){
      copyToClipboard(copyText, ()=>{
        setIsCopied(true)
        setTimeout(()=>{
          setIsCopied(false)
        }, 1000)
      }, ()=>{
        setIsCopied(false)
        alert("Failed to copy text")
      });
    }
  }, [copyText]);

  useEffect(() => {
    if(value){
      setSelectedDate(value)
    }
  }, [value])

  useEffect(() => {
    if(persistValue){
      const savedDate = localStorage.getItem("selectedDate");
      if(savedDate){
        const dt = DateTime.fromJSDate(new Date(+savedDate));
        if(dt.isValid){
          changeSelectedDate(dt.toJSDate())
        }
      }
    }
  }, [persistValue, changeSelectedDate])

  useEffect(() => {
    if(persistValue && selectedDate){
      localStorage.setItem("selectedDate", selectedDate.getTime().toString())
    }
  }, [selectedDate, persistValue])

  return (
    <>
      <div className="date-range-wrapper flex justify-center relative h-full">
        <div className="flex items-center bg-[#EBECEF] rounded-lg">
          <ChevronLeftIcon className="w-10 h-10 mr-2 cursor-pointer text-gray-800" onClick={handlePrevDayClick} />
          <span className="cursor-pointer font-medium text-base leading-tight text-gray-700" onClick={() => setShowDateRange(true)}>
            {selectedDate.toDateString()}
          </span>
          <ChevronRightIcon className="w-10 h-10 ml-2 cursor-pointer text-gray-800" onClick={handleNextDayClick} />
        </div>
        <div className="flex items-center ml-2 bg-[#EBECEF] rounded-lg p-3 hidden lg:flex" onClick={() => handleSelect(new Date())}>
          <CalendarIcon className="w-6 h-6 cursor-pointer text-gray-800" />
        </div>

        {showDateRange && (
          <>
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowDateRange(false);
              }}
            >
              <Calendar className="absolute right-0 top-10 shadow-md z-50" date={selectedDate} onChange={handleSelect} weekStartsOn={1} />
            </OutsideClickHandler>
          </>
        )}

        {!!copyText && <Tooltip content={isCopied ? "Copied!" : copyTooltip}>
          <div className="flex items-center ml-2 bg-[#EBECEF] rounded-lg p-3 cursor-pointer" onClick={onCopyButtonClick}>
            <ClipboardCopyIcon className={`text-black w-6 ${isCopied ? "text-purple-500" : ""}`} />
          </div>
        </Tooltip>}
      </div>
    </>
  );
};

export default Calender;
