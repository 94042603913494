import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useBookingSession } from "../../context/BookingSession";
import LoadingSpinner from "../../components/Shared/LoadingSpinner";
import { TDashboardView, TMainTrackAgeFilters } from "../../interfaces";
import { ILineItemWithOrder, ISessionWithBookings } from "../../interfaces/Session";
import { BookingDriverSeats } from "../../components/Common";
import Session from "./Session";
import { useSession as useAuthSession } from "../../components/Session";
import { useDevice } from "../../hooks/useDevice";
import { DateTime } from "luxon";
import { getDatetimeFromSku } from "../../utilities";
import DriverRegistrationProvider from "../../context/DriverRegistration";
import BookingDetailsModal from "./BookingDetailsModal";
import DriverRegistrationModal from "./DriverRegistrationModal";

interface Props {
  dashboardView: TDashboardView;
  ageFilter: TMainTrackAgeFilters;
  changeAgeFilter: (val: TMainTrackAgeFilters) => void;
}

function SessionTable({ dashboardView, ageFilter, changeAgeFilter }: Props) {
  const [selectedBooking, setSelectedBooking] = useState<ILineItemWithOrder | null>();
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showRegistrationModal, setRegistrationModal] = useState(false);
  const sessionRowsRef = useRef<Array<HTMLTableRowElement | null>>([]);
  const { permissions } = useAuthSession();
  const trackStaff = permissions?.roles?.includes("TRACK_STAFF");
  const hasRrmFeatureAccess = permissions?.features?.includes("RRM");
  const { loading, filteredSessions, track: view, date, originalSessions } = useBookingSession();
  const ageOptions = useMemo(() => (view === "MAIN_TRACK" ? Object.values(TMainTrackAgeFilters) : ["All Sessions"]), [view]);
  const isComplexView = useMemo(() => dashboardView === "COMPLEX_VIEW", [dashboardView]);

  const { isIPad, isMobile } = useDevice();

  const totalNoOfColumns = useMemo(() => {
    let numOfPublicColumn = 9;

    if (!isIPad) {
      numOfPublicColumn += 1;
    }

    if (isComplexView) {
      numOfPublicColumn += 3; //bookingtime, paid_price, barcode
    }

    //racereadymsg
    if (!trackStaff || hasRrmFeatureAccess) {
      numOfPublicColumn += 1;
    }

    return numOfPublicColumn;
  }, [isComplexView, trackStaff, hasRrmFeatureAccess, isIPad]);

  const sessions = useMemo(
    () =>
      (dashboardView ? originalSessions : filteredSessions).filter((session) => {
        let valid = true;
        if (view === "MAIN_TRACK") {
          if (ageFilter !== TMainTrackAgeFilters.ALL && !(session.option1 || "").toLowerCase().includes(ageFilter.toLowerCase())) {
            valid = false;
          }
        }
        return valid;
      }),
    [filteredSessions, ageFilter, view, originalSessions, dashboardView]
  );

  const orderIds = useMemo(
    () =>
      sessions
        .map((session) => session.bookings.map((booking) => booking.order))
        .flat()
        .map((order) => String(order.id)),
    [sessions]
  );

  useEffect(() => {
    if(isMobile){
      return 
    }
    const dtNow = DateTime.local({
      zone: "Australia/Sydney",
    });

    if (sessions.length && DateTime.fromJSDate(date).toFormat("dd-MM-yyyy") === dtNow.toFormat("dd-MM-yyyy")) {
      const currentSession = sessions.reduce((prevVal, currVal) => {
        const prevDt = getDatetimeFromSku(prevVal.sku, view);
        const currDt = getDatetimeFromSku(currVal.sku, view);

        if (dtNow.toMillis() - (currDt?.toMillis() || 0) < dtNow.toMillis() - (prevDt?.toMillis() || 0) && dtNow.toMillis() - (currDt?.toMillis() || 0) > 0) {
          return currVal;
        }

        return prevVal;
      }, sessions[0]);

      sessionRowsRef.current = sessionRowsRef.current.slice(0, sessions.length);

      const currentSessionIndex = sessionRowsRef.current.findIndex((sessiondiv) => sessiondiv?.dataset?.sku === currentSession.sku);

      sessionRowsRef.current[currentSessionIndex - 1 >= 0 ? currentSessionIndex - 1 : 0]?.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  }, [sessions, view, date, isMobile]);

  const handleViewOrderClick = useCallback((booking: ILineItemWithOrder) => {
    setSelectedBooking(booking);
    setShowDetailsModal(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setShowDetailsModal(false);
    setSelectedBooking(null);
  }, []);

  const handleViewRegistrationModalOpen = useCallback((booking: ILineItemWithOrder) => {
    setSelectedBooking(booking);
    setRegistrationModal(true);
  }, []);
  const handleModalRegistrationModalClose = useCallback(() => {
    setRegistrationModal(false);
    setSelectedBooking(null);
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <DriverRegistrationProvider orderIds={orderIds}>
      <div className="dashboard-session-table-wrapper m-7 h-[calc(100%_-_3.5rem)] overflow-auto">
        {/* models */}
        {!!selectedBooking && showDetailsModal && (
          <BookingDetailsModal
            modal="ORDER_DETAIL"
            show={showDetailsModal}
            order={selectedBooking.order}
            handleClose={handleModalClose}
            handleViewRegistrationModalOpen={handleViewRegistrationModalOpen}
          />
        )}

        {!!selectedBooking && showRegistrationModal && (
          // <DriverRegistrationProvider
          //   orderIds={[String(selectedBooking.order.id)]}
          // >
          <DriverRegistrationModal
            show={showRegistrationModal}
            order={selectedBooking.order}
            handleClose={handleModalRegistrationModalClose}
            view={view}
            selectedDate={date}
            handleViewOrderClick={handleViewOrderClick}
          />
          // </DriverRegistrationProvider>
        )}
        {/* models */}
        <table className="min-w-full h-[1px]">
          <thead>
            <tr>
              {!isIPad && isComplexView && (
                <th className="min-w-[160px] p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col">
                  QR Code
                </th>
              )}

              <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col">
                Staff
              </th>
              {/* TODO: add filter based on view and filter the session data */}
              <th className="min-w-[140px] p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col">
                <select
                  className="mt-1 w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  value={ageFilter}
                  onChange={(e) => changeAgeFilter(e.target.value as TMainTrackAgeFilters)}
                >
                  {ageOptions.map((opt) => {
                    return <option key={opt}>{opt}</option>;
                  })}
                </select>
              </th>

              <th className="min-w-[72px] p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col">
                Price
              </th>
              <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col">
                Qty
              </th>
              <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left" scope="col">
                Booking name
              </th>
              <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left" scope="col">
                Notes
              </th>
              <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left" scope="col">
                Occasion
              </th>
              {isComplexView && (
                <>
                  <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left" scope="col">
                    Booking Time
                  </th>
                  <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 whitespace-nowrap" scope="col">
                    Price Paid
                    <br />
                    (Average Price)
                  </th>
                </>
              )}
              <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10" scope="col">
                DRIVERS
              </th>
              {(!trackStaff || hasRrmFeatureAccess) && (
                <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left" scope="col">
                  Race Ready SMS
                </th>
              )}
              <th className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 text-left" scope="col">
                Registration status
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300">
            {(sessions || []).map((session: ISessionWithBookings, index) => (
                <Fragment key={session.id}>
                  <Session
                    session={session}
                    dashboardView={dashboardView}
                    handleViewOrderClick={handleViewOrderClick}
                    handleViewRegistrationModalOpen={handleViewRegistrationModalOpen}
                  />
                  <tr ref={(el) => (sessionRowsRef.current[index] = el)} data-sku={session.sku} className="scroll-mt-[35px]">
                    <td colSpan={totalNoOfColumns} className="p-0 ">
                      <BookingDriverSeats session={session} view={view} />
                    </td>
                  </tr>
                </Fragment>
              ))}
            {sessions.length === 0 && (
              <tr>
                <td colSpan={totalNoOfColumns}>
                  <div className="text-lg text-gray-400 text-center h-screen mt-[20%]">No records to display</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </DriverRegistrationProvider>
  );
}

export default SessionTable;
