import React, { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ILineItemWithOrder, ISessionWithBookings, TWetherData } from "../interfaces/Session";
import { DateTime } from "luxon";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useFirebase } from "../components/Firebase";
import { IOrder, IProduct, IProductVariant } from "shopify-api-node";
import { HIDDEN_TIMES } from "../constants/Dashboard";
import { TBookingType, TMainTrackAgeFilters } from "../interfaces";
import { getDatetimeFromSku, getTrackFromSku } from "../utilities";
// import axios from "axios";

export interface IBookingSessionContext {
  originalSessions: ISessionWithBookings[];
  filteredSessions: ISessionWithBookings[];
  loading: boolean;
  date: Date;
  refreshSessions: () => void;
  track: TBookingType;
  applySearchFilter: (val: string) => void;
  getOrdersByVariantId: (variantId: number) => Promise<IOrder[]>;
  product?: IProduct;
  ageFilter: TMainTrackAgeFilters;
  changeAgeFilter: (val: TMainTrackAgeFilters) => void;
  sessionWiseWetherData: TWetherData;
}

const BookingSessionContext = React.createContext<IBookingSessionContext>({
  originalSessions: [],
  filteredSessions: [],
  loading: true,
  date: new Date(),
  refreshSessions: () => {},
  track: "MAIN_TRACK",
  applySearchFilter: () => {},
  getOrdersByVariantId: () => Promise.resolve([]),
  product: undefined,
  ageFilter: TMainTrackAgeFilters.ALL,
  changeAgeFilter: () => {},
  sessionWiseWetherData: {}
});

interface Type {
  date: Date;
  track: TBookingType;
  children: ReactNode;
  wetherData?: any
}

const getProductHandle = (date: Date, track: TBookingType) => {
  const dateString = DateTime.fromJSDate(date).toFormat("yyLLdd");
  if (track === "MAIN_TRACK") {
    return `${dateString}mt`;
  } else if (track === "MINI_TRACK") {
    return `0${dateString}mi`;
  } else if (track === "VR") {
    return `${dateString}vr`;
  } else if (track === "JUNIOR_TRACK") {
    return `${dateString}jt`;
  } else if (track === "INTERMEDIATE_TRACK") {
    return `${dateString}it`;
  }
};

const basicBookingOrderFilter = (orders: IOrder[]) => {
  return orders.filter((order) => !order.customer?.first_name?.includes("SPARE") && !order.customer?.first_name?.includes("KIDS"));
};

const BookingSessionProvider: FC<Type> = ({ children, date, track, wetherData }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [searchText, setSearchText] = useState("");
  const searchTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const productHandle = useMemo(() => getProductHandle(date, track), [date, track]);
  const [originalSessions, setOriginalSessions] = useState([] as ISessionWithBookings[]);
  const [filteredSessions, setFilteredSessions] = useState([] as ISessionWithBookings[]);
  const [product, setProduct] = useState<IProduct>();
  const [ageFilter, setAgeFilter] = useState<TMainTrackAgeFilters>(TMainTrackAgeFilters.ALL);

  const firebase = useFirebase();
  const db = useMemo(() => firebase.db, [firebase]);
  const productRef = useMemo(() => collection(db, "products"), [db]);
  const orderRef = useMemo(() => collection(db, "orders"), [db]);

  const mapOrdersAndVariants = useCallback(
    async (vas: IProductVariant[], ors: IOrder[]) => {
      let lineItemWithOrder = [] as ILineItemWithOrder[];

      // Include the order information in our line item object
      ors.forEach((order) => {
        order.line_items.forEach((lineItem) => {
          lineItemWithOrder.push({
            order,
            ...lineItem,
          });
        });
      });

      // Include the line items (and orders) in the variants array
      let newSessions = await Promise.all(
        vas.map(async (variant) => {
          const { id } = variant;
          let bookings = lineItemWithOrder.filter((li) => li.variant_id === id);

          return {
            ...variant,
            bookings,
          };
        })
      );

      setOriginalSessions(newSessions);
      // setLoading(false);
    },
    []
  );

  const loadSessions = useCallback(() => {
    return new Promise((resolve, reject) => {
      console.log("Loading sessions", productHandle);
      const prodQuery = query(productRef, where("handle", "==", productHandle));
      getDocs(prodQuery)
        .then((qs) => {
          if (qs.empty) {
            console.log("Query results empty");
            setFilteredSessions([]);
            setLoading(false);
            resolve(true);
          }
          qs.forEach((doc) => {
            const productData = doc.data();
            console.log("Got product data", productData);
            const { variants, id } = productData;
            setProduct(productData as IProduct);

            let filteredVariants = [] as IProductVariant[];
            if (track === "MAIN_TRACK") {
              filteredVariants = variants.filter((vari: IProductVariant) => !vari.option3?.includes("Time") && !HIDDEN_TIMES.includes(vari.option3!));
            } else {
              filteredVariants = variants;
            }

            const orderQuery = query(orderRef, where("product_ids", "array-contains", id));
            getDocs(orderQuery)
              .then((qs) => {
                let tempOrders = [] as IOrder[];

                qs.forEach((doc) => {
                  const orderData = doc.data() as IOrder;
                  tempOrders.push(orderData);
                });

                const filteredOrders = basicBookingOrderFilter(tempOrders);

                mapOrdersAndVariants(filteredVariants, filteredOrders);
                setLoading(false);
                resolve(true);
              })
              .catch((e) => {
                throw e;
              });
          });
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          reject(e);
        });
    });
  }, [productHandle, orderRef, productRef, track, mapOrdersAndVariants]);

  const refreshSessions = useCallback(() => {
    setLoading(true);
    return loadSessions();
  }, [loadSessions]);

  const applySearchFilter = useCallback((val: string) => {
    if (searchTimeoutRef.current) clearTimeout(searchTimeoutRef.current);
    searchTimeoutRef.current = setTimeout(() => setSearchText(val), 500);
  }, []);

  const changeAgeFilter = useCallback((val: TMainTrackAgeFilters) => {
    setAgeFilter(val);
  }, []);

  const withoutRefunds = useMemo(() => {
    // ** Error in this code, it's filtering out the entire session, instead of just the refunded race inside the session ** //

    // const withoutRefunds = newSessions.filter(x => {
    //   const refunds = x.bookings.filter(y => y.order.financial_status === 'refunded');
    //   return refunds.length === 0
    // })
    return originalSessions.map((session) => {
      const bookings = session.bookings.filter(
        (booking) =>
          !booking.order.refunds
            .map((refund) => refund.refund_line_items)
            .flat()
            .find((e) => e.line_item_id === booking.id && e.quantity === booking.quantity)
      );

      return { ...session, bookings };
    });
  }, [originalSessions]);

  const getOrdersByVariantId = useCallback(
    async (variantId: number) => {
      if (product?.id) {
        const orderQuery = query(orderRef, where("product_ids", "array-contains", product.id));

        const qs = await getDocs(orderQuery);
        let orders = qs.docs.map((doc) => doc.data() as IOrder);
        orders = basicBookingOrderFilter(orders);
        return orders
          .filter((order) => order.line_items.map((li) => li.variant_id).includes(variantId))
          .filter((order) => {
            const lineItem = order.line_items.find((li) => li.variant_id === variantId);
            if (Array.isArray(order.refunds)) {
              const isRefunded = !!order.refunds
                .map((refund) => refund.refund_line_items)
                .flat()
                .find((rli) => rli.line_item_id === lineItem?.id && rli.quantity === lineItem?.quantity);
              if (isRefunded) {
                return false;
              }
            }
            return true;
          });
      }
      return [];
    },
    [orderRef, product]
  );

  // mapping of wether data with sessions
  const sessionWiseWetherData = useMemo(() => {
    const data: TWetherData = {};

    const isToday = DateTime.fromJSDate(date, { zone: "Australia/Sydney" }).startOf('day').equals(DateTime.now().setZone("Australia/Sydney").startOf('day'));
    
    if(!wetherData){
      return data
    }

    if(!isToday){
      const dailyData = wetherData.daily || []
      const forecastData = dailyData.find((weatherData: any) => DateTime.fromJSDate(date, { zone: "Australia/Sydney" }).startOf('day').equals(DateTime.fromMillis(weatherData.dt * 1000, { zone: "Australia/Sydney" }).setZone("Australia/Sydney").startOf('day')))
      if(forecastData){
        filteredSessions.forEach(session => {
          if(session.sku){
            data[session.sku] = forecastData
          }
        })
      }
      return data;
    }else{
      filteredSessions.forEach(session => {
        if(session.sku){
          const dt = getDatetimeFromSku(session.sku, getTrackFromSku(session.sku))
          if(dt?.isValid){
            const sessionTs = dt.toMillis();
            const hourlyData = wetherData.hourly || [];
  
            // Find nearest timestamp in weather data
            const nearestWeather = hourlyData.reduce((nearest: any, current: any) => {
              if (!nearest) return current;
              
              const currentDiff = Math.abs(current.dt * 1000 - sessionTs);
              const nearestDiff = Math.abs(nearest.dt * 1000 - sessionTs);
              
              return currentDiff < nearestDiff ? current : nearest;
            }, null);
  
            if (nearestWeather) {
              data[session.sku] = nearestWeather;
            }
          }
        }
      });
    }

    return data;
  }, [filteredSessions, wetherData, date])

  //Filter the sessions
  useEffect(() => {
    const newSessions = structuredClone(withoutRefunds) as ISessionWithBookings[];

    setFilteredSessions(
      newSessions.filter((session) => {
        if (searchText) {
          const bookings = session.bookings.filter((x) => {
            const fName = x.order.customer?.first_name?.toLowerCase();
            const lName = x.order.customer?.last_name?.toLowerCase();
            const email = x.order.customer?.email?.toLowerCase();

            return fName?.includes(searchText) || lName?.includes(searchText) || email?.includes(searchText);
          });
          session.bookings = bookings;
          return bookings.length;
        }
        return true;
      })
    );
  }, [withoutRefunds, searchText]);

  useEffect(() => {
    refreshSessions().catch(console.log);
  }, [refreshSessions]);

  return (
    <BookingSessionContext.Provider
      value={{
        originalSessions,
        filteredSessions,
        loading,
        date,
        refreshSessions,
        track,
        applySearchFilter,
        getOrdersByVariantId,
        product,
        ageFilter,
        changeAgeFilter,
        sessionWiseWetherData
      }}
    >
      {children}
    </BookingSessionContext.Provider>
  );
};
export default BookingSessionProvider;

export function useBookingSession() {
  return useContext(BookingSessionContext);
}
