import { useCallback, useMemo, useState } from "react";
import { ILineItemWithOrder } from "../../interfaces/Session";
import { useCheckIn } from "../../context/Checkin";
import axios from "axios";
import { CheckIcon } from "@heroicons/react/solid";
import { ICheckInReq } from "../../interfaces/CheckIn";
import { useDriverRegistration } from "../../context/DriverRegistration";

const CheckInComponent = (props: {
  booking: ILineItemWithOrder;
  selectedDate: Date;
  getDriverCount: (booking: ILineItemWithOrder) => number;
}) => {
  const { bookings, bookingsLoading } = useDriverRegistration();
  const { booking, selectedDate, getDriverCount } = props;

  const orderId = useMemo(() => booking.order.id, [booking]);
  const productId = useMemo(() => booking?.product_id || 0, [booking]);
  const variantId = useMemo(() => booking?.variant_id || 0, [booking]);

  const [checkiingIn, setCheckiingIn] = useState(false);
  const { data, loadCheckedInOrders } = useCheckIn();

  const checkedInData = useMemo(
    () =>
      data.find(
        (c) =>
          c.orderId === orderId &&
          c.productId === productId &&
          c.variantId === variantId
      ),
    [data, orderId, productId, variantId]
  );

  const count = useMemo(() => checkedInData?.count || 0, [checkedInData]);

  const doCheckIn = useCallback(
    (type: "INCREMENT" | "DECREMENT" | "SET", count?: number) => async () => {
      if (checkiingIn) {
        return;
      }
      setCheckiingIn(true);
      try {
        const payload: ICheckInReq = {
          ts: selectedDate.getTime(),
          orderId: booking.order.id,
          productId: booking?.product_id || 0,
          variantId: booking?.variant_id || 0,
          increment: false,
          decrement: false,
        };

        if (type === "INCREMENT") {
          payload.increment = true;
        }
        if (type === "DECREMENT") {
          payload.decrement = true;
        }
        if (type === "SET") {
          payload.count = count || 0;
          delete payload.increment;
          delete payload.decrement;
        }
        // "http://localhost:5001/hyper-karting/us-central1/checkIn"||
        await axios.post(
          "https://us-central1-hyper-karting.cloudfunctions.net/checkIn",
          payload
        );
        await loadCheckedInOrders();
      } catch (error) {
        console.log("Error in checkIn", error);
      } finally {
        setCheckiingIn(false);
      }
    },
    [checkiingIn, selectedDate, booking, loadCheckedInOrders]
  );

  const driversCount = useMemo(
    () => getDriverCount(booking) || 0,
    [getDriverCount, booking]
  );

  const isGlobalCheckInEnable = useMemo(() => count === 0, [count]);

  const noDriverRegistrationExists = useMemo(() => {
    if (!bookingsLoading) {
      const bookingDocument = bookings[booking.order.id];
      return !bookingDocument?.drivers?.length;
    } else {
      return false;
    }
  }, [bookings, bookingsLoading, booking]);

  return (
    <div className="flex gap-2 max-h-7">
      <div
        className={`p-1 flex items-center border rounded-md ${
          isGlobalCheckInEnable
            ? noDriverRegistrationExists
              ? "bg-gray-200"
              : "bg-indigo-500"
            : "pointer-events-none bg-green-500"
        }`}
        onClick={doCheckIn("SET", driversCount)}
      >
        <CheckIcon
          className={`w-4 cursor-pointer ${
            noDriverRegistrationExists ? "text-black" : "text-white"
          }`}
        />
      </div>
    </div>
  );
};

export default CheckInComponent;
